import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Tag } from "antd";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import CalendarHeatmap from "react-calendar-heatmap";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import "react-calendar-heatmap/dist/styles.css";

import './styles.less';
import useAuth from "hooks/useAuth";
import { getAdminUserSummaryStats, getAdminUserDailyStats, getAdminAggregatedUsagesByAppUsing, getAdminUserDailyAppStats } from "apis/usage";
import { userGet } from "apis/user";

CalendarHeatmap.prototype.getHeight = function () {
  return this.getWeekWidth() + (this.getMonthLabelSize() - this.props.gutterSize);
};

CalendarHeatmap.prototype.getTransformForWeekdayLabels = function () {
  if (this.props.horizontal) {
    return `translate(0, ${this.getMonthLabelSize()})`;
  }
  return null;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const COLORS = ["#0072f0", "#2687f2", "#4d9cf5", "#73b1f7", "#99c7f9"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, type }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentChart = (percent * 100).toFixed(0);

  return (
    <text x={x} y={y} fill="#000" textAnchor="middle" dominantBaseline="central">
      {percentChart >= 5 && type !== "Others" ? `${percentChart}%` : ""}
    </text>
  );
};

const USAGES_PER_PAGE = 5;

const UserUsageDetail = () => {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [userDetail, setUserDetail] = useState(null);

  // Analytic
  const [stats, setStats] = useState([
    { name: "THIS_WEEK", stat: null, change: null, changeType: null },
    { name: "LAST_WEEK", stat: null, change: null, changeType: null },
    { name: "THIS_MONTH", stat: null, change: null, changeType: null },
    { name: "LAST_MONTH", stat: null, change: null, changeType: null },
  ]);
  const [usagesByDay, setUsagesByDay] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [appsUsingsData, setAppsUsingsData] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [chartType, setChartType] = useState("Weekly");
  const [loadingUsages, setLoadingUsages] = useState(false);
  const [loadingUsages2, setLoadingUsages2] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const onPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onNextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let name = payload[0].name;
      if (name === "Others") {
        name = "Others";
      }

      if (name === "file_enhancement") {
        name = "File Enhancement";
      }

      return (
        <div className="bg-white border border-[#ddd] p-3 rounded-lg text-sm">
          <p className="label">{`${name}: ${convertToDuration(payload[0].value)}`}</p>
        </div>
      );
    }

    return null;
  };

  //require login
  const { user } = useAuth();

  const convertToDuration = (value) => {
    let diffInMilliSeconds = Math.round(value / 1000);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = diffInMilliSeconds;

    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  };

  const handleDailyStats = (dailyStats) => {
    let dates = {};
    let toDate = moment().endOf("year").add(6, "days");
    const fromDate = moment().startOf("year").subtract(7, "days");

    while (moment(fromDate).isBefore(moment(toDate), "day")) {
      dates[toDate.format("YYYY-MM-DD")] = 0;
      toDate = toDate.subtract(1, "day");
    }

    dates[toDate.format("YYYY-MM-DD")] = 0;

    dailyStats.forEach(item => {
      const date = item.date;
      if (date in dates) {
        dates[date] += (item.totalDuration / 3600000);
      }
    });

    let totalHoursUsage = 0;
    const newChart = Object.keys(dates).map(item => {
      const hours = _.round(dates[item], 1);

      let level = 0;

      if (hours === 0) {
        level = 0;
      } else if (hours < 1) {
        level = 1;
      } else if (hours < 2) {
        level = 2;
      } else if (hours < 3) {
        level = 3;
      } else {
        level = 4;
      }
      totalHoursUsage += hours;

      return {
        date: item,
        hours,
        level
      };
    });

    setTotalHours(totalHoursUsage);
    setChartData(newChart);
  };

  const handleChartData = (data) => {
    let oldData = Object.keys(data).map(item => {
      return {
        type: item === "unknown" ? "Others" : item,
        hours: _.round(data[item] / 3600000, 1),
        duration: _.round(data[item], 1)
      };
    }).filter(pie => pie.duration > 0).sort((a, b) => b.duration - a.duration);

    let newData = [];
    const maxLength = 6;

    // Nếu tìm thấy dùng CrystalSound MVO thì đưa vào Other
    const crystalsoundIndex = oldData.findIndex(item => item.type === "CrystalSound MVO.exe");

    if (crystalsoundIndex !== -1) {
      const othersIndex = oldData.findIndex(item => item.type === "Others");
      if (othersIndex !== -1) {
        const newDuration = oldData[othersIndex].duration + oldData[crystalsoundIndex].duration;
        oldData[othersIndex] = {
          ...oldData[othersIndex],
          type: "Others",
          duration: _.round(newDuration, 1),
          hours: _.round(newDuration / 3600000, 1)
        };
        oldData = oldData.filter(item => item.type !== "CrystalSound MVO.exe");
      } else {
        oldData[crystalsoundIndex] = {
          ...oldData[crystalsoundIndex],
          type: "Others"
        };
      }
    }

    if (oldData.length > maxLength) {
      newData = oldData.slice(0, maxLength);

      const dataSlice = oldData.slice(maxLength);
      const othersIndex = oldData.findIndex(item => item.type === "Others");

      let totalDuration = 0;
      dataSlice.length > 0 && dataSlice.forEach(item => {
        totalDuration += item.duration;
      });

      // Khi data tồn tại Others
      if (othersIndex !== -1) {
        newData[othersIndex] = {
          ...newData[othersIndex],
          duration: _.round((newData[othersIndex].duration + totalDuration), 1),
          hours: _.round((newData[othersIndex].duration + totalDuration) / 3600000, 1)
        };
      } else {
        // if (newData[maxLength - 1].duration < totalDuration) {
        newData[maxLength - 1] = {
          type: "Others",
          duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
          hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
        };
        // }
      }
    } else {
      newData = [...oldData];
    }

    newData = newData.sort((a, b) => b.duration - a.duration);
    const findIndex = newData.findIndex(item => item.type === "Others");

    if (findIndex !== -1) {
      const tempData2 = newData.filter(item => item.type !== "Others");
      newData = [...tempData2, newData[findIndex]];
    }

    let colorIndex = 0;
    newData = newData.map(item => {
      if (item.type === "Others") {
        return {
          ...item,
          color: "#f4f4f4"
        };
      } else {
        return {
          ...item,
          color: COLORS[colorIndex++]
        };
      }
    });

    return newData;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [result] = await Promise.all([
          userGet(userId),
        ]);

        setUserDetail(result);
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [userId]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingUsages(true);

        if (user && userDetail) {
          const [resultSummaryStats, resultDailyStats] = await Promise.all([
            getAdminUserSummaryStats({
              email: userDetail.email
            }),
            getAdminUserDailyStats({
              email: userDetail.email,
              fromDate: moment().startOf("year").subtract(7, "days").toISOString(),
              toDate: moment().endOf("year").toISOString(),
            }),
          ]);

          const summaryStats = resultSummaryStats.data;
          const dailyStats = resultDailyStats.data;

          handleDailyStats(dailyStats);

          const {
            thisWeekDuration,
            lastWeekDuration,
            weekComparison,
            thisMonthDuration,
            lastMonthDuration,
            monthComparison
          } = summaryStats;

          const newStats = [
            {
              name: "THIS_WEEK",
              stat: _.round(thisWeekDuration / 60000),
              change: `${weekComparison}%`,
              changeType: weekComparison > 0 ? "increase" : weekComparison < 0 ? "decrease" : "unchanged",
            },
            {
              name: "LAST_WEEK",
              stat: _.round(lastWeekDuration / 60000),
              change: null,
              changeType: null,
            },
            {
              name: "THIS_MONTH",
              stat: _.round(thisMonthDuration / 60000),
              change: `${monthComparison}%`,
              changeType: monthComparison > 0 ? "increase" : monthComparison < 0 ? "decrease" : "unchanged",
            },
            {
              name: "LAST_MONTH",
              stat: _.round(lastMonthDuration / 60000),
              change: null,
              changeType: null,
            }
          ];

          setStats(newStats);
          setLoadingUsages(false);
        }
      } catch (error) {
        console.log("error:", error);
        setLoadingUsages(false);
      }
    })();
  }, [user, userDetail]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingUsages2(true);

        if (user && userDetail) {
          const time = chartType === "Monthly" ? [0, 29] : [0, 6];
          const fromDate = moment().clone().subtract(time[1], "days");
          const toDate = moment().clone().subtract(time[0], "days");

          const body = {
            email: userDetail.email
          };

          if (chartType !== "All") {
            body.fromDate = fromDate;
            body.toDate = toDate;
          }

          const [resultUsagesByAppUsing, resultDailyAppStats] = await Promise.all([
            getAdminAggregatedUsagesByAppUsing(body),
            getAdminUserDailyAppStats(body),
          ]);

          const summaryStats = resultUsagesByAppUsing.data;
          const dailyAppStats = resultDailyAppStats.data;
          const newAppsUsingsData = handleChartData(summaryStats);

          const newUsagesByDay = dailyAppStats.flatMap(item =>
            Object.entries(item.appsUsage).map(([source, duration]) => ({
              date: item.date,
              source,
              duration
            }))
          );

          if (newUsagesByDay.length > 0) {
            const total = Math.ceil(newUsagesByDay.length / USAGES_PER_PAGE);
            setTotalPages(total);
          } else {
            setTotalPages(1);
          }

          setAppsUsingsData(newAppsUsingsData);
          setUsagesByDay(newUsagesByDay);
          setLoadingUsages2(false);
        }
      } catch (error) {
        console.log("error:", error);
        setLoadingUsages2(false);
      }
    }

    fetchData();
  }, [user, userDetail, chartType]);

  const currentUsagesByDay = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * USAGES_PER_PAGE;
    const lastPageIndex = firstPageIndex + USAGES_PER_PAGE;
    return usagesByDay.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, usagesByDay]);

  const handleChartType = (chartType) => {
    if (!loadingUsages2) {
      setCurrentPage(1);
      setChartType(chartType);
    }
  };

  const renderSourceUsage = (sourceUsage) => {
    let source = sourceUsage;

    if (source === "Unknown") {
      source = "Unknown";
    }

    if (source === "file_enhancement") {
      source = "File Enhancement";
    }

    return source;
  };

  if (!loading && !userDetail) {
    return (
      <main className="flex flex-col items-center justify-center w-full h-screen">
        <h1 className="font-extrabold tracking-widest text-gray-900 text-9xl">404</h1>
        <div className="absolute px-2 text-sm text-white rounded rotate-12 bg-primary">User Not Found</div>
        <button className="mt-5">
          <Link
            to="/v1/users"
            className="relative block px-6 py-3 text-sm font-medium text-white rounded-md hover:text-white bg-primary"
          >
            Go Back
          </Link>
        </button>
      </main>
    );
  }

  return (
    <div className="flex overflow-hidden bg-gray-100">
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative z-0 flex-1 focus:outline-none">
          <div className="py-6">
            <div className="flex flex-col px-4 mx-auto sm:px-6 md:px-8 gap-y-4">
              <div className="px-4 py-5 bg-white border-b border-gray-200 rounded-lg shadow sm:px-6">
                <h3 className="text-[#EF3661] font-semibold text-xl">User Info</h3>
                {loading ? (
                  <div className="flex flex-col gap-3 mt-8">
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="h-5 sm:w-[60px] text-[#A0ADB9] flex items-center">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[40px]"></div>
                      </div>
                      <div className="flex items-center sm:flex-1 sm:pl-5">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[160px]"></div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="h-5 sm:w-[60px] text-[#A0ADB9] flex items-center">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[40px]"></div>
                      </div>
                      <div className="flex items-center sm:flex-1 sm:pl-5">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[160px]"></div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="h-5 sm:w-[60px] text-[#A0ADB9] flex items-center">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[40px]"></div>
                      </div>
                      <div className="flex items-center sm:flex-1 sm:pl-5">
                        <div className="h-3 bg-gray-200 rounded-md sm:w-[160px]"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-3 mt-8">
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="truncate sm:w-[60px] text-primary">Email:</div>
                      <div className="sm:flex-1 sm:pl-5">
                        <div className="text-black/85">{userDetail?.email}</div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="truncate sm:w-[60px] text-primary">Role:</div>
                      <div className="sm:flex-1 sm:pl-5">
                        {userDetail?.role === "free" ? (
                          <Tag>{userDetail?.role?.toUpperCase()}</Tag>
                        )
                          : (
                            <Tag color="green">{userDetail?.role?.toUpperCase()}</Tag>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col flex-wrap text-sm sm:flex-row">
                      <div className="truncate sm:w-[60px] text-primary">Country:</div>
                      <div className="sm:flex-1 sm:pl-5">
                        <div className="text-black/85">{userDetail?.countryCode}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <dl className="grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-4">
                  {stats.map((item) => {
                    let statName = "";
                    let statStatistic = "";
                    let compareTime = "";

                    if (item.name === "THIS_WEEK") {
                      statName = "This week";
                      compareTime = "than last week";
                    } else if (item.name === "LAST_WEEK") {
                      statName = "Last week";
                    } else if (item.name === "THIS_MONTH") {
                      statName = "This month";
                      compareTime = "than last month";
                    } else if (item.name === "LAST_MONTH") {
                      statName = "Last month";
                    }

                    if (item.stat !== null) {
                      statStatistic = `${item.stat} ${item.stat > 1 ? "mins" : "min"}`;
                    }

                    return (
                      <div key={item.name} className="px-4 py-5 bg-white border rounded-lg shadow sm:p-6">
                        <dt className="text-xl font-medium text-center text-primary">{statName}</dt>
                        <dd className="flex items-center justify-between mt-1 md:block lg:flex">
                          {statStatistic ? (
                            <div className={`flex items-baseline mt-1 text-2xl ${item.change === null ? "mx-auto" : ""}`}>
                              {statStatistic}
                            </div>
                          )
                            :
                            (
                              <div className="mt-4 w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5 max-w-sm animate-pulse"></div>
                            )
                          }
                          {
                            item.changeType && item.change !== "NaN%" && item.change !== "Infinity%" &&
                            <div className="flex flex-col items-end">
                              <div
                                className={classNames(
                                  item.changeType === "increase" ? "text-[#14CC26]" : item.changeType === "decrease" ? "text-[#EF3661]" : "text-primary",
                                  "inline-flex items-baseline py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                                )}
                              >
                                {item.changeType === "increase" ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 w-5 h-5 mr-2 text-[#14CC26]" fill="currentColor" viewBox="0 0 320 512">
                                    <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                  </svg>
                                ) : item.changeType === "increase" ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 w-5 h-5 mr-2 text-[#EF3661]" fill="currentColor" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 w-5 h-5 mr-2 text-primary" fill="currentColor" viewBox="0 0 320 512">
                                    <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                  </svg>
                                )}
                                <span className="sr-only">{item.changeType === "increase" ? "Increased" : "Decreased"} by</span>
                                {item.change}
                              </div>
                              <span className="text-[#b0b0b0] text-sm">{compareTime}</span>
                            </div>
                          }
                          {
                            item.change === "Infinity%" &&
                            <div className="flex flex-col items-end">
                              <div className="text-[#14CC26] inline-flex items-baseline py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 w-5 h-5 mr-2 text-[#14CC26]" fill="currentColor" viewBox="0 0 320 512">
                                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                </svg>
                                +∞ %
                              </div>
                              <span className="text-[#b0b0b0] text-sm">{compareTime}</span>
                            </div>
                          }
                          {
                            item.change === "NaN%" &&
                            <div className="flex flex-col items-end">
                              <div className="text-primary inline-flex items-baseline py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 w-5 h-5 mr-2 text-primary" fill="currentColor" viewBox="0 0 320 512">
                                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                                </svg>
                                0.0%
                              </div>
                              <span className="text-[#b0b0b0] text-sm">{compareTime}</span>
                            </div>
                          }
                        </dd>
                      </div>
                    );
                  })}
                </dl>
              </div>

              <div className="px-4 py-5 bg-white border-b border-gray-200 rounded-lg shadow sm:px-6">
                <h3 className="text-[#EF3661] font-semibold text-xl">Activity</h3>
                <div className="calendar-heatmap-container relative mx-auto !my-4">
                  <CalendarHeatmap
                    startDate={moment().endOf("year").subtract(372, "days").format("YYYY-MM-DD")}
                    endDate={moment().endOf("year").add(6, "days").format("YYYY-MM-DD")}
                    values={chartData}
                    classForValue={(value) => {
                      const animatePulse = loadingUsages ? "animate-pulse" : "";

                      if (!value) {
                        return `color-level-0 ${animatePulse}`;
                      }
                      return `${value.level}` < 4
                        ? `color-level-${value.level} ${animatePulse}`
                        : `color-level-4  ${animatePulse}`;
                    }}
                    tooltipDataAttrs={(value) => {
                      if (value.date) {
                        const dataTooltip = `${value.hours} ${value.hours > 1 ? "hours" : "hour"} on ${value.date}`;

                        return {
                          "data-tip": dataTooltip
                        };
                      } else {
                        return {
                          "data-tip": ""
                        };
                      }
                    }}
                    showMonthLabels={true}
                    showWeekdayLabels={true}
                    showOutOfRangeDays={true}
                    monthLabels={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    weekdayLabels={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
                  />
                  <ReactTooltip />

                  <div className="flex flex-col-reverse justify-between gap-4 mt-6 ml-6 text-sm lg:flex-row lg:ml-14">
                    <div>
                      Your total usage time for this year is {_.round(totalHours, 1)} {_.round(totalHours, 1) > 1 ? "hours" : "hour"}
                    </div>
                    <div className="flex items-center gap-1 ml-auto lg:ml-0">
                      <span className="mr-1">Less</span>
                      {[0, 1, 2, 3, 4].map(item => {
                        let tooltipContent = "";

                        if (item === 0) {
                          tooltipContent = "No usage";
                        } else if (item === 1) {
                          tooltipContent = "Less than an hour per day";
                        } else if (item === 2) {
                          tooltipContent = "From 1 hour to 2 hours per day";
                        } else if (item === 3) {
                          tooltipContent = "From 2 hours to 3 hours per day";
                        } else if (item === 4) {
                          tooltipContent = "More than 3 hour per day";
                        }

                        return (
                          <svg key={item} width={18} height={18} data-for="color-tooltip" data-tip={tooltipContent}>
                            <rect width={18} height={18} rx={2} ry={2} className={`color-level-${item}`} />
                          </svg>
                        );
                      })}
                      <span className="ml-1">More</span>
                    </div>
                  </div>

                  <ReactTooltip id="color-tooltip" />
                </div>
              </div>

              <div className="px-4 py-5 bg-white border-b border-gray-200 rounded-lg shadow sm:px-6">
                <h3 className="text-[#EF3661] font-semibold text-xl">Usage records</h3>
                <div className="mt-8">
                  <div className="flex flex-col gap-4 xl:grid xl:grid-cols-12 pie-chart-container">
                    <div className="flex flex-col items-center xl:col-span-5">
                      <div className="inline-flex rounded-md shadow-sm gap-[2px]" role="group">
                        <button
                          type="button"
                          className={`${chartType === "Weekly" ? "!border-primary !text-primary" : ""} ${loadingUsages2 ? "cursor-default" : "cursor-pointer"} w-[80px] px-4 py-2 text-sm font-medium text-[#C4C4C4] bg-white border border-[#C4C4C4] rounded-l-md hover:bg-gray-100 hover:text-primary hover:border-primary`}
                          onClick={() => handleChartType("Weekly")}
                        >
                          Weekly
                        </button>
                        <button
                          type="button"
                          className={`${chartType === "Monthly" ? "!border-primary !text-primary" : ""} ${loadingUsages2 ? "cursor-default" : "cursor-pointer"} w-[80px] px-4 py-2 text-sm font-medium text-[#C4C4C4] bg-white border border-[#C4C4C4] hover:bg-gray-100 hover:text-primary hover:border-primary`}
                          onClick={() => handleChartType("Monthly")}
                        >
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`${chartType === "All" ? "!border-primary !text-primary" : ""} ${loadingUsages2 ? "cursor-default" : "cursor-pointer"} w-[80px] px-4 py-2 text-sm font-medium text-[#C4C4C4] bg-white border border-[#C4C4C4] rounded-r-md hover:bg-gray-100 hover:text-primary hover:border-primary`}
                          onClick={() => handleChartType("All")}
                        >
                          All
                        </button>
                      </div>
                      {loadingUsages2 ?
                        <>
                          <ResponsiveContainer className="!h-[400px] block sm:hidden md:block lg:hidden xl:block pie-chart-large">
                            <PieChart>
                              <Legend
                                align="center"
                                verticalAlign="bottom"
                              />
                              <Pie
                                data={[{
                                  type: "",
                                  duration: 1
                                }]}
                                fill="#eee"
                                dataKey="duration"
                                nameKey="type"
                                labelLine={false}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                legendType="none"
                                startAngle={90}
                                endAngle={-270}
                              >
                                <Cell fill="#eee" className="animate-pulse" />
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                          <ResponsiveContainer className="!h-[400px] hidden sm:block md:hidden lg:block xl:hidden pie-chart-mobile">
                            <PieChart>
                              <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                              />
                              <Pie
                                data={[{
                                  type: "",
                                  duration: 1
                                }]}
                                fill="#eee"
                                dataKey="duration"
                                nameKey="type"
                                labelLine={false}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                legendType="none"
                                startAngle={90}
                                endAngle={-270}
                              >
                                <Cell fill="#eee" className="animate-pulse" />
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </>
                        :
                        <>
                          {appsUsingsData.length > 0 ? (
                            <>
                              <ResponsiveContainer className="!h-[400px] block sm:hidden md:block lg:hidden xl:block pie-chart-large">
                                <PieChart>
                                  <Legend
                                    align="center"
                                    verticalAlign="bottom"
                                    formatter={(value) => {
                                      let valueRender = value;
                                      if (valueRender === "Others") {
                                        valueRender = "Others";
                                      }

                                      if (valueRender === "file_enhancement") {
                                        valueRender = "File Enhancement";
                                      }

                                      return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                                    }}
                                  />
                                  <Pie
                                    data={appsUsingsData}
                                    fill="#8884d8"
                                    dataKey="duration"
                                    nameKey="type"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={40}
                                    legendType="circle"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                    {appsUsingsData.map((entry, index) => {
                                      return (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                      );
                                    })}
                                  </Pie>
                                  <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                              </ResponsiveContainer>
                              <ResponsiveContainer className="!h-[400px] hidden sm:block md:hidden lg:block xl:hidden pie-chart-mobile">
                                <PieChart>
                                  <Legend
                                    layout="vertical"
                                    align="right"
                                    verticalAlign="middle"
                                    formatter={(value) => {
                                      let valueRender = value;
                                      if (valueRender === "Others") {
                                        valueRender = "Others";
                                      }

                                      if (valueRender === "file_enhancement") {
                                        valueRender = "File Enhancement";
                                      }

                                      return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                                    }}
                                  />
                                  <Pie
                                    data={appsUsingsData}
                                    fill="#8884d8"
                                    dataKey="duration"
                                    nameKey="type"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={40}
                                    legendType="circle"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                    {appsUsingsData.map((entry, index) => {
                                      return (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                      );
                                    })}
                                  </Pie>
                                  <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                              </ResponsiveContainer>
                            </>
                          ) : (
                            <>
                              <ResponsiveContainer className="!h-[400px] block sm:hidden md:block lg:hidden xl:block pie-chart-large">
                                <PieChart>
                                  <Legend
                                    align="center"
                                    verticalAlign="bottom"
                                    formatter={(value) => {
                                      let valueRender = value;
                                      if (valueRender === "Others") {
                                        valueRender = "Others";
                                      }

                                      if (valueRender === "file_enhancement") {
                                        valueRender = "File Enhancement";
                                      }

                                      return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                                    }}
                                    style={{ display: "inline-flex", alignItems: "center" }}
                                  />
                                  <Pie
                                    data={[{
                                      type: "No data",
                                      duration: 1
                                    }]}
                                    fill="#eee"
                                    dataKey="duration"
                                    nameKey="type"
                                    labelLine={false}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={40}
                                    legendType="circle"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                              <ResponsiveContainer className="!h-[400px] hidden sm:block md:hidden lg:block xl:hidden pie-chart-mobile">
                                <PieChart>
                                  <Legend
                                    layout="vertical"
                                    align="right"
                                    verticalAlign="middle"
                                    formatter={(value) => {
                                      let valueRender = value;
                                      if (valueRender === "Others") {
                                        valueRender = "Others";
                                      }

                                      if (valueRender === "file_enhancement") {
                                        valueRender = "File Enhancement";
                                      }

                                      return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                                    }}
                                  />
                                  <Pie
                                    data={[{
                                      type: "No data",
                                      duration: 1
                                    }]}
                                    fill="#eee"
                                    dataKey="duration"
                                    nameKey="type"
                                    labelLine={false}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={40}
                                    legendType="circle"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            </>
                          )
                          }
                        </>
                      }
                    </div>
                    <div className="xl:col-span-7">
                      <div className="flex flex-col">
                        <div className="overflow-x-auto">
                          <div className="inline-block min-w-full pb-2 align-middle">
                            <div className="overflow-hidden border border-gray-200 shadow sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                      NO.
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                      Duration
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                      Source
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                      Date
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {loadingUsages2 === true ? (
                                    <Fragment>
                                      {Array.from({ length: 5 }).map((item, index) => (
                                        <tr key={index} className="h-[53px]">
                                          <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap"><div className="w-12 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"><div className="h-2 bg-gray-200 rounded-full w-42 dark:bg-gray-300"></div></td>
                                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"><div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"><div className="h-2 bg-gray-200 rounded-full w-36 dark:bg-gray-300"></div></td>
                                        </tr>
                                      ))}
                                    </Fragment>
                                  )
                                    :
                                    (
                                      <Fragment>
                                        {currentUsagesByDay.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{(currentPage - 1) * USAGES_PER_PAGE + index + 1}</td>
                                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{convertToDuration(item.duration)}</td>
                                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{renderSourceUsage(item.source)}</td>
                                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{item.date}</td>
                                            </tr>
                                          );
                                        })}
                                        {(currentUsagesByDay.length <= 0) && (
                                          <tr key={"no-data"}>
                                            <td colSpan={4} className="px-6 py-4 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                              No data
                                            </td>
                                          </tr>
                                        )}
                                        <tr key={"paginate"}>
                                          <td colSpan={4} rowSpan={2} className="px-6 py-4 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                            <div className="flex items-center justify-end gap-4">
                                              <span>{currentUsagesByDay.length > 0 ? ((currentPage - 1) * USAGES_PER_PAGE + 1) : 0} - {(currentPage - 1) * USAGES_PER_PAGE + currentUsagesByDay.length} / {usagesByDay.length}</span>
                                              <span className="flex items-center">
                                                <ChevronLeftIcon onClick={onPreviousPage} className={`w-6 h-6 ${currentPage === 1 ? "opacity-30" : "cursor-pointer"}`} aria-hidden="true" />
                                                <ChevronRightIcon onClick={onNextPage} className={`w-6 h-6 ${currentPage === totalPages ? "opacity-30" : "cursor-pointer"}`} aria-hidden="true" />
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      </Fragment>
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default UserUsageDetail;
