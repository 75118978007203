import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Affix, Modal, Form, Input, Row, Col, Button } from 'antd';
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';

import './styles.less';

import logo from 'assets/logo.png';

import { auth } from 'utils/firebase';
import { signOut } from 'firebase/auth';

import useAuth from 'hooks/useAuth';

const { Header } = Layout;

const smartTrim = (str, length, delim, appendix) => {
  if (str.length <= length) return str;

  var trimmedStr = str.substr(0, length + delim.length);

  var lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

  if (trimmedStr) trimmedStr += appendix;
  return trimmedStr;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const getMenuItems = (role, navigate) => {
  if (role === 'super') {
    return [
      {
        key: 'Users',
        label: 'Users',
        children: [
          { key: 'users', label: 'Users', onClick: () => navigate('/v1/users') },
          { key: 'teams', label: 'Teams', onClick: () => navigate('/v1/users/teams') },
        ],
      },
      { key: 'usages', label: 'Usages', onClick: () => navigate('/v1/usages') },
      { key: 'billings', label: 'Billing', onClick: () => navigate('/v1/billings') },
      { key: 'subscriptions', label: 'Subscriptions', onClick: () => navigate('/v1/subscriptions') },
      {
        key: 'Other',
        label: 'Other',
        children: [
          { key: 'master', label: 'Master Data', onClick: () => navigate('/v1/master/versions') },
          { key: 'reports', label: 'Reports', onClick: () => navigate('/v1/reports') },
          { key: 'contacts', label: 'Contacts', onClick: () => navigate('/v1/contacts') },
        ],
      },
    ];
  }

  if (role === 'sale') {
    return [
      { key: 'users', label: 'User', onClick: () => navigate('/v1/users') },
      { key: 'usages', label: 'Usages', onClick: () => navigate('/v1/usages') },
      { key: 'billings', label: 'Billing', onClick: () => navigate('/v1/billings') },
      { key: 'contacts', label: 'Contacts', onClick: () => navigate('/v1/contacts') },
    ];
  }

  if (role === 'support') {
    return [
      { key: 'usages', label: 'Usages', onClick: () => navigate('/v1/usages') },
      { key: 'reports', label: 'Reports', onClick: () => navigate('/v1/reports') },
    ];
  }

  return [];
};

const AdminLayout = () => {
  const { user: userProfile } = useAuth();
  let navigate = useNavigate();
  const pathMenu = useLocation().pathname.split('/')
  const [selectedKey, setSelectedKey] = useState("")
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const onClose = () => {
    setChangePasswordModal(false);
  };

  useEffect(() => {
    if (pathMenu[2] !== undefined) {
      setSelectedKey(pathMenu[2].trim())
    } else {
      setSelectedKey('dashboard')
    }
  }, [pathMenu]);

  const menu = {
    items: [
      {
        key: '1',
        label: 'Change Password',
        onClick: () => setChangePasswordModal(true),
      },
      {
        key: '2',
        label: 'Logout',
        icon: <LogoutOutlined />,
        onClick: () => {
          signOut(auth);
          navigate('/');
        },
      },
    ],
  };

  const menuItems = getMenuItems(userProfile?.role, navigate);

  return (
    <div className='admin-layout'>
      <Layout>
        <Affix offsetTop={0}>
          <Header className="header">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: 4, display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 140 }} className="flex items-center">
                  <img src={logo} className="logo" alt="Neep" />
                </div>
                <div style={{ flex: 1, marginLeft: 40 }}>
                  <Menu
                    mode="horizontal"
                    defaultSelectedKeys={[selectedKey]}
                    items={menuItems}
                  />
                </div>
              </div>
              <div style={{
                flex: 1, height: '64px', width: '100%',
                backgroundColor: 'white', display: 'flex',
                flexFlow: 'row-reverse', alignItems: 'center'
              }}>
                <Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
                  <div style={{
                    height: '40px', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                  }}>
                    <Avatar shape="circle" size="large" icon={<UserOutlined />} style={{ marginRight: '8px' }} />
                    {smartTrim(_.get(userProfile, 'email', ''), 15, ' ', ' ...')}
                    <DownOutlined style={{ fontSize: '22px' }} theme="outlined" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Header>
        </Affix>
        <Layout>
          <Outlet />
        </Layout>
      </Layout>

      <Modal
        open={changePasswordModal}
        title="Change Password"
        onCancel={onClose}
        destroyOnClose={true}
        centered
        footer={null}
      >
        <Form
          {...layout}
          name="changePasswordForm"
          className="change-password-form"
          labelAlign="left"
          onFinish={async (values) => {
            // try {
            //   const { currentPassword, newPassword } = values;
            //   const user = firebase.auth().currentUser;
            //   const credential = firebase.auth.EmailAuthProvider.credential(
            //     user.email,
            //     currentPassword
            //   );
            //   await user.reauthenticateWithCredential(credential);
            //   auth.currentUser.updatePassword(newPassword);
            //   onClose();
            //   Modal.success({
            //     title: "Change Password Successfully",
            //     content: "Your password has been changed successfully.",
            //   });
            // } catch (e) {
            //   if (e.code === "auth/wrong-password") {
            //     Modal.error({
            //       title: "Change Password",
            //       content: "Wrong current password",
            //     });
            //   } else {
            //     Modal.error({
            //       title: "Change Password",
            //       content: e.message ?? "Error",
            //     });
            //   }
            // }
          }}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please enter your current password.",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Current Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter your new password.",
              },
              () => ({
                validator(_, value) {
                  if (value === undefined) return Promise.resolve();

                  if (value.length < 8)
                    return Promise.reject("Password must have at least 8 characters");

                  var hasUpperCase = /[A-Z]/.test(value);
                  var hasLowerCase = /[a-z]/.test(value);
                  var hasNumbers = /\d/.test(value);
                  //var hasNonalphas = /\W/.test(value);

                  if (hasUpperCase && hasLowerCase && hasNumbers) {
                    return Promise.resolve();
                  } else {
                    let msg = "";
                    if (hasUpperCase === false) {
                      msg = `${msg} Password must have at least 1 Uppercase Letter `;
                    }

                    if (hasLowerCase === false) {
                      msg = `${msg} Password must have at least 1 Lowercase Letter `;
                    }

                    if (hasNumbers === false) {
                      msg = `${msg} Password must have at least 1 Number`;
                    }

                    return Promise.reject(msg);
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <div
            className="ant-modal-footer"
            style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
          >
            <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
              <Col
                className="gutter-row"
                span={24}
                style={{ textAlign: "right", paddingRight: 20 }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ fontWeight: "bold" }}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  )
};

export default AdminLayout;
